import axios from 'axios';
import '../utils/retryMechanism/retrySessionInterface';

// eslint-disable-next-line import/prefer-default-export
export const setHeaders = (
  token: string,
  platform: string,
  version: string,
  language: string
) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['platform'] = platform;
  axios.defaults.headers.common['X-APP-VERSION'] = version;
  axios.defaults.headers.common['Accept-Language'] = language;
  axios.defaults.withCredentials = true;
};
