const questionsTest: { [key: string]: string } = {
  'Select all the symptoms': "%s's symptoms",
  'interfere with your sleep': 'Sleep disruption',
  'protective products': "%s's protective products",
};

const getQuestionReplacementText = (text: string, name: string): string => {
  const keys = Object.keys(questionsTest);

  for (let i = 0, l = keys.length; i < l; i += 1) {
    if (text.includes(keys[i])) {
      return questionsTest[keys[i]].replace('%s', name).replace("s's", "s'");
    }
  }

  return text;
};

export default getQuestionReplacementText;
