import ClientInterfaceAbstract from './ClientInteface.abstract';

interface MessagePoster {
  postMessage: (message?: string) => void;
}

interface InterfaceHandlers {
  onSessionExpiry: MessagePoster;
  onCompleted: MessagePoster;
  onCancelled: MessagePoster;
  onError: MessagePoster;
}

interface Webkit {
  messageHandlers: InterfaceHandlers;
}

class IOSInterface extends ClientInterfaceAbstract {
  private isNativeHandler: boolean = false;
  private client: InterfaceHandlers | ClientInterfaceAbstract;

  constructor(fallback: ClientInterfaceAbstract) {
    super();
    const urlParams = new URLSearchParams(window.location.search);
    const wclibv = urlParams.get('wclib_v');

    if (
      'webkit' in window &&
      wclibv &&
      wclibv.length > 0 &&
      wclibv >= '1.0.0'
    ) {
      this.client = (window.webkit as Webkit).messageHandlers;
      this.isNativeHandler = true;
    } else {
      this.client = fallback;
    }
  }

  onCompleted(message?: string): void {
    if (this.isNativeHandler) {
      (this.client as InterfaceHandlers).onCompleted.postMessage(message);
    } else if (this.client instanceof ClientInterfaceAbstract) {
      this.client.onCompleted(message);
    }
  }

  onCancelled(message?: string): void {
    if (this.isNativeHandler) {
      (this.client as InterfaceHandlers).onCancelled.postMessage(message);
    } else if (this.client instanceof ClientInterfaceAbstract) {
      this.client.onCancelled(message);
    }
  }

  onError(message?: string): void {
    if (this.isNativeHandler) {
      (this.client as InterfaceHandlers).onError.postMessage(message);
    } else if (this.client instanceof ClientInterfaceAbstract) {
      this.client.onError(message);
    }
  }

  onSessionExpiry(message?: string): void {
    if (this.isNativeHandler) {
      (this.client as InterfaceHandlers).onSessionExpiry.postMessage(message);
    } else if (this.client instanceof ClientInterfaceAbstract) {
      this.client.onSessionExpiry(message);
    }
  }
}

export default IOSInterface;
