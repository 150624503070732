import { create } from 'zustand';
import { PLATFORM } from '../enums';

export type SessionStore = {
  token: string | null;
  setToken: (token: string) => void;

  platform: PLATFORM;
  setPlatform: (platform: PLATFORM) => void;

  isEdit: boolean;
  setEdit: (isEdit: boolean) => void;

  patientId: string | null;
  setPatientId: (id: string) => void;

  patientFirstName: string | null;
  patientLastName: string | null;
  setPatientName: (details: { firstName: string; lastName: string }) => void;
  getPatientFullName: () => string;

  userProcedureId: string | null;
  setUserProcedureId: (userProcedureId: string) => void;
};

const useSessionStore = create<SessionStore>((set, get) => ({
  token: null,
  setToken: (token: string) => set({ token }),

  platform: PLATFORM.ANDROID,
  setPlatform: platform => set({ platform }),

  isEdit: false,
  setEdit: isEdit => set({ isEdit }),

  patientId: null,
  setPatientId: (patientId: string) => set({ patientId }),

  patientFirstName: null,
  patientLastName: null,
  setPatientName: details =>
    set({
      patientFirstName: details.firstName,
      patientLastName: details.lastName,
    }),
  getPatientFullName: () =>
    `${get().patientFirstName} ${get().patientLastName}`,

  userProcedureId: null,
  setUserProcedureId: (userProcedureId: string) => set({ userProcedureId }),
}));

export default useSessionStore;
