import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend) // Load translations from /public/locales
  .use(initReactI18next) // Binds i18next to React
  .init({
    lng: 'en',
    fallbackLng: 'en',
    ns: ['personalization', 'baseline', 'reports', 'diaries', 'common'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}/translation.json', // Load path for translations
    },
    interpolation: { escapeValue: false },
  });

export default i18n;
