const getAuthToken = () => {
  let token = null;
  const cookies = document.cookie.split(';');
  cookies.every(element => {
    const cookie = element.trim().replace(/Bearer Bearer/, 'Bearer');
    if (cookie.startsWith('Authorization=Bearer ')) {
      token = cookie.substring('Authorization=Bearer '.length, cookie.length);
      return false;
    }
    return true;
  });
  return token;
};

export default getAuthToken;
