import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import * as Sentry from '@sentry/react';
import SETTINGS from 'settings';
import Root from './pages/Root';
import './i18n';

if (SETTINGS.NODE_ENV !== 'development') {
  Sentry.init({ dsn: SETTINGS.SENTRY_DSN });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
