type Task = () => Promise<void>;

class FailedRequestQueue {
  private static queue: Task[] = [];

  static addToQueue(task: Task): void {
    this.queue.push(task);
  }

  static getQueue(): Task[] {
    return this.queue;
  }
}

export default FailedRequestQueue;
