export enum STATE {
  COMPLETED = 'completed',
  DEFERRED = 'deferred',
  ERROR = 'error',
  SESSIONEXPIRED = 'sessionexpired',
}

export enum PLATFORM {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum INDICATION {
  BLADDER = 'bladder',
  BOWEL = 'bowel',
}
