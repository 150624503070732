import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import ClientInterfaceAbstract from './ClientInterface/ClientInteface.abstract';

const handleApiErrors = (
  e: Error | AxiosError | unknown,
  handler: ClientInterfaceAbstract
) => {
  if (axios.isAxiosError(e) && e.response) {
    if (e.response.status === 401) {
      handler.onError('Session Expired. Please try again!');
    } else {
      handler.onError(e.response.data.error);
    }
  } else {
    handler.onError('Something went wrong');
  }

  Sentry.captureException(e);
};

export default handleApiErrors;
