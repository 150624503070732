// Read the query parameters for "platform" and "X-APP-VERSION" from the URL
// and return an array as [platform, version]

import { PLATFORM } from '../enums';

const getHeaders = (): [PLATFORM, string, string] => {
  const urlParams = new URLSearchParams(window.location.search);
  const platform =
    (urlParams.get('platform') as PLATFORM) ||
    (urlParams.get('Platform') as PLATFORM) ||
    PLATFORM.IOS;
  const version = urlParams.get('X-APP-VERSION') ?? '0';
  const language = urlParams.get('Accept-Language') || 'en';
  return [platform, version, language];
};

export default getHeaders;
