import { DateTime } from 'luxon';

function ordinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function formatDate(
  dateString: string,
  sourceFormat = 'M/d/yyyy',
  withYear = false
): string {
  const date = DateTime.fromFormat(dateString, sourceFormat);
  const day = Number.parseInt(date.toFormat('d'), 10);
  const suffix = ordinal(day);
  return `${date.toFormat('EEEE, MMMM')} ${suffix}${
    withYear ? `, ${date.toFormat('yyyy')}` : ''
  }`;
}

export default formatDate;
