import { STATE } from 'enums';
import ERRORS from 'pages/strings';
import ClientInterfaceAbstract from './ClientInteface.abstract';

type ChangeSearchParams = {
  token: string;
  state?: STATE;
  message?: string;
  patient_id?: string;
};

function doesStateExist() {
  const currentSearchParams = new URLSearchParams(window.location.search);
  const state = currentSearchParams.get('state');

  return state && state.length > 0;
}

function setWindowLocation(newLocation: string): void {
  window.location.search = newLocation;
}

function performAction(newLocation: string) {
  if (doesStateExist()) {
    return;
  }

  setWindowLocation(newLocation);
}

class ParamsInterface extends ClientInterfaceAbstract {
  private token: string;
  private patient_id: string | null = null;

  constructor({ token, patient_id }: ChangeSearchParams) {
    super();
    this.token = token;

    if (patient_id && patient_id.length > 0) {
      this.patient_id = patient_id;
    }
  }

  onCompleted(message: string): void {
    performAction(
      new URLSearchParams({
        ...this.toSearchParams(),
        message,
        state: STATE.COMPLETED,
      }).toString()
    );
  }

  onCancelled(message: string): void {
    performAction(
      new URLSearchParams({
        ...this.toSearchParams(),
        message,
        state: STATE.DEFERRED,
      }).toString()
    );
  }

  onError(message: string): void {
    performAction(
      new URLSearchParams({
        ...this.toSearchParams(),
        message: message || ERRORS.SOMETHING_WENT_WRONG,
        state: STATE.ERROR,
      }).toString()
    );
  }

  onSessionExpiry(): void {
    performAction(
      new URLSearchParams({
        ...this.toSearchParams(),
        message: 'Something went wrong',
        state: STATE.ERROR,
      }).toString()
    );
  }

  private toSearchParams(): { [key: string]: string } {
    const result = {
      token: this.token,
      patient_id: this.patient_id || '',
    };

    if (this.patient_id && this.patient_id.length > 0) {
      result.patient_id = this.patient_id;
    }

    return result;
  }
}

export default ParamsInterface;
