import { ProcessQueue } from 'utils/retryMechanism';
import { useSessionStore } from 'stores';

export interface RetrySessionInterface {
  retrySession(newToken: string): void;
}

declare global {
  interface Window {
    RetrySessionInterface: RetrySessionInterface;
  }
}

window.RetrySessionInterface = {
  retrySession: (newToken: string) => {
    console.log('New token received from client:', newToken);

    const sessionStore = useSessionStore.getState();
    sessionStore.setToken(newToken);

    ProcessQueue.processQueue(newToken)
      .then(() => {
        console.log('All queued requests have been processed successfully.');
      })
      .catch(error => {
        console.error('Error while processing the queue:', error);
      });
  },
};
