import axios from 'axios';
import { PLATFORM } from 'enums';
import { getHeaders, NavigationHandler } from 'utils';
import FailedRequestQueue from './failedRequestQueue';

class ProcessQueue {
  private static isProcessing = false;

  static async processQueue(newToken: string): Promise<void> {
    if (this.isProcessing) return;

    const platform: PLATFORM = getHeaders()[0];
    const navigationHandler = new NavigationHandler(platform, newToken);

    this.isProcessing = true;

    const queue = FailedRequestQueue.getQueue();

    axios.defaults.headers['Authorization'] = `Bearer ${newToken}`;

    const promises = [];
    while (queue.length > 0) {
      const currentTask = queue.shift();
      if (currentTask) {
        promises.push(
          currentTask()
            .then(() => {
              console.log('Task ran successfully');
            })
            .catch(error => {
              navigationHandler.onError(`something went wrong - ${error}`);
            })
        );
      }
    }
    try {
      await Promise.all(promises);
    } catch (error) {
      navigationHandler.onError('something went wrong');
    }

    this.isProcessing = false;
  }
}
export default ProcessQueue;
