import { PersonalizationAnswer } from 'pages/Personalization/dto/PersonalizationAnswers.dto';
import PersonalizationQuestionFetchDto from 'pages/Personalization/dto/PersonalizationQuestionFetch.dto';

export interface GroupedAnswer {
  expectedQuestionId: number;
  question: string;
  answers: PersonalizationAnswer[];
  order: number;
}

export const groupAnswers = (
  answers: PersonalizationAnswer[],
  questions: PersonalizationQuestionFetchDto.UserExpectedQuestion[]
) => {
  // Return empty object if questions is null or empty
  if (!questions || questions.length === 0) {
    return [];
  }
  const grouped: GroupedAnswer[] = [];
  answers.forEach(answer => {
    const currGroup = grouped.find(
      x => x.expectedQuestionId === answer.question_id
    );
    const question = questions.find(
      x => x.question.expectedQuestionId === answer.question_id
    );
    if (!currGroup && question) {
      grouped.push({
        expectedQuestionId: answer.question_id,
        question: question.question.text,
        order: question.question.order,
        answers: [answer],
      });
    } else if (currGroup) {
      currGroup.answers.push(answer);
    }
  });

  return grouped;
};
