import axios, { AxiosError } from 'axios';
import { handleApiErrors } from 'utils';
import ClientInterfaceAbstract from 'utils/ClientInterface/ClientInteface.abstract';
import FailedRequestQueue from './failedRequestQueue';

type ExecuteWithRetryParams = {
  triggerFetch: () => Promise<void>;
  navigationHandler: ClientInterfaceAbstract;
  onCatch?: (error: Error | AxiosError | unknown) => void;
  onFinally?: () => void;
  onStart?: () => void;
};

const executeWithRetry = async ({
  triggerFetch,
  navigationHandler,
  onCatch,
  onFinally,
  onStart,
}: ExecuteWithRetryParams) => {
  try {
    if (onStart) {
      onStart();
    }
    await triggerFetch();
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 401) {
        FailedRequestQueue.addToQueue(triggerFetch);
        navigationHandler.onSessionExpiry('Session Expired');
        return;
      }
      if (e.response.status !== 401 && onCatch) {
        onCatch(e);
      } else {
        handleApiErrors(e, navigationHandler);
      }
    } else {
      handleApiErrors(e, navigationHandler);
    }
  } finally {
    if (onFinally) {
      onFinally();
    }
  }
};
export default executeWithRetry;
