import * as Sentry from '@sentry/react';
import React, { lazy, Suspense } from 'react';
import { Settings } from 'luxon';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { setHeaders } from 'factories/base';
import i18n from 'i18n';
import { PLATFORM } from 'enums';
import { getAuthToken, getHeaders } from 'utils';
import Redirect from './Redirect';

const NotFound = lazy(() => import('./404'));
const ErrorBoundary = lazy(() => import('./ErrorBoundary'));
const Personalization = lazy(() => import('./Personalization'));
const ManagerPersonalization = lazy(() => import('./Personalization/Manager'));
const BaselinePage = lazy(() => import('./Baseline'));
const EvaluationReports = lazy(() => import('./Reports/Evaluation/page'));
const ManagerEvaluationReports = lazy(
  () => import('./Reports/Evaluation/Manager/page')
);
const ScheduleSharePage = lazy(
  () => import('./Reports/Evaluation/Manager/Share/page')
);
const SharingHistoryPage = lazy(
  () => import('./Reports/Evaluation/Manager/Share/History/page')
);
const DiariesPage = lazy(() => import('./Reports/Diaries/page'));
const DiarySummaryPage = lazy(() => import('./Reports/Diaries/Summary'));
const DiariesGenerationPage = lazy(
  () => import('./Reports/Diaries/Generate/page')
);
const BaselineReports = lazy(() => import('./Reports/Baseline'));
const HcpEnrollSearchPage = lazy(
  () => import('./Reports/Evaluation/Manager/HcpEnrollSearch/page')
);
const HcpSchedulePage = lazy(
  () => import('./Reports/Evaluation/Manager/HcpEnrollSearch/[id]/page')
);

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'personalization',
    element: <Personalization />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'personalization/manager',
    element: <ManagerPersonalization />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'baseline',
    element: <BaselinePage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation',
    element: <EvaluationReports />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation/manager',
    element: <ManagerEvaluationReports />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation/manager/share',
    element: <ScheduleSharePage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation/manager/hcpenroll',
    element: <HcpEnrollSearchPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation/manager/hcpenroll/:id',
    element: <HcpSchedulePage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/evaluation/manager/share/history',
    element: <SharingHistoryPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/diaries',
    element: <DiariesPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/diaries/summary',
    element: <DiarySummaryPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/diaries/generate',
    element: <DiariesGenerationPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'redirect',
    element: <Redirect />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'report/baseline',
    element: <BaselineReports />,
    errorElement: <ErrorBoundary />,
  },
]);

const Root = () => {
  const params = new URLSearchParams(window.location.search);
  const [platform, version, language]: [PLATFORM, string, string] =
    getHeaders();
  const token = getAuthToken() ?? params.get('token') ?? '';
  setHeaders(token, platform, version, language);
  i18n.changeLanguage(language);
  Settings.defaultLocale = language;
  i18n.on('languageChanged', lng => {
    Settings.defaultLocale = lng;
  });
  return (
    <Sentry.ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default Root;
