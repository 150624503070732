import { PLATFORM } from 'enums';
import ParamsInterface from './ClientInterface/ParamsInterface';
import IOSInterface from './ClientInterface/IOSInterface';
import AndroidInterface from './ClientInterface/AndroidInterface';
import ClientInterfaceAbstract from './ClientInterface/ClientInteface.abstract';

class NavigationHandler {
  private clientHandler: ClientInterfaceAbstract;

  constructor(platform: PLATFORM, token: string, patientId?: string) {
    const paramsInterface = new ParamsInterface({
      token,
      patient_id: patientId || '',
    });

    if (platform === PLATFORM.IOS) {
      this.clientHandler = new IOSInterface(paramsInterface);
    } else if (platform === PLATFORM.ANDROID) {
      this.clientHandler = new AndroidInterface(paramsInterface);
    } else {
      this.clientHandler = paramsInterface;
    }
  }

  onCompleted(message?: string): void {
    this.clientHandler.onCompleted(message);
  }

  onCancelled(message?: string): void {
    this.clientHandler.onCancelled(message);
  }

  onError(message?: string): void {
    this.clientHandler.onError(message);
  }

  onSessionExpiry(message?: string): void {
    this.clientHandler.onSessionExpiry(message);
  }
}

export default NavigationHandler;
