const sortObjectList = <Type>({
  list,
  key,
}: {
  list: Type[];
  key: keyof Type;
}): Type[] =>
  list.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });

export default sortObjectList;
