import ClientInterfaceAbstract from './ClientInteface.abstract';

interface GlobalAndroidInteface {
  onCompleted(message?: string): void;
  onCancelled(message?: string): void;
  onError(message?: string): void;
  onSessionExpiry(message?: string): void;
}

class AndroidInterface extends ClientInterfaceAbstract {
  private client: GlobalAndroidInteface;

  constructor(fallback: ClientInterfaceAbstract) {
    super();
    if ('Android' in window) {
      this.client = window.Android as GlobalAndroidInteface;
    } else {
      this.client = fallback;
    }
  }

  onCompleted(message?: string): void {
    this.client.onCompleted(message);
  }

  onCancelled(message?: string): void {
    this.client.onCancelled(message);
  }

  onError(message?: string): void {
    this.client.onError(message);
  }

  onSessionExpiry(message?: string): void {
    this.client.onSessionExpiry(message);
  }
}

export default AndroidInterface;
